.text-yellow-500 {
  color: #FF772E
}
.text-yellow-700 {
  color: #FB4853;
}
.hover\:bg-yellow-600:hover {
  background-color: #FB4853;
}
.bg-yellow-500, .hover\:bg-yellow-500:hover {
  background-color: #FF772E;
}
.border-yellow-500 {
  border-color: #FF772E;
}
